<template>
  <div>
    <v-row>
      <v-col lg="12">
        <visit :isAdmin="true"  @rowSelected="rowSelected($event)"  :height="100"> >
        </visit>        
      </v-col>
         
     
    </v-row>
  </div>
</template>

<script>
import Visit from "@/views/TechnicalAssistance/TechnicalVisit/TechnicalVisit";
export default {
  components: {
        Visit
    },

   data() {
    return {
      parentID: 0,
      tvId:0,
     
    };
  },

  methods: {
    rowSelected(rows) {
      
    },
   
  },
};
</script>
