<template>
  <s-crud
    @returnObject="refresh($event)"
    no-border
    title="Registro de Visitas Técnicas"
    :config="config"
    @save="sav($event)"
    add
    @addEvent="clearForm()"
    edit
    @EditEvent="update($event)"
    @rowSelected="rowSelected($event)"
    remove
    :filter="filter"
    search-input
    excel
  > 
    <template v-slot:filter>
      <v-container>
        <v-row>
           <!-- :min="$fun.getDate(-25)"-->
          
          <v-col lg="2" class="s-col-form" cols="12">            
            <s-date
              label="Fecha Inicio"  
              v-model="filter.DateBegin"/> 
          </v-col>
          <v-col lg="2" class="s-col-form" cols="12">            
            <s-date
              label="Fecha Fin"  
              v-model="filter.DateEnd"/> 
          </v-col>
          <v-col lg="6" class="s-col-form" cols="12">           
            <s-select-supervice
              clearable
              label="Supervisor"
              v-model="filter.TvSupervisorPrsID"
              full /> 
          </v-col>          
          <v-col lg="2" class="s-col-form" cols="12">
            <s-select-definition
              v-model="filter.TypeVisit"
               clearable
              :def="1202"
              label="Tipo Visita"  />
          </v-col>
         
        </v-row>
      </v-container>
    </template>
    <template scope="props">
      <v-container style="" v-if="props.item != null" class="pb-0">
        <v-row justify="center">
          <v-col lg="2" class="s-col-form" cols="12">
              <!-- :min="$fun.getDate(-25)" -->
              <!-- :max="$fun.getDate()" -->
            <s-date
              label="Fecha"
             
            
              v-model="props.item.TvDate"
            ></s-date>
          </v-col>
          <v-col lg="2" class="s-col-form" cols="12">
            <s-select-definition
              v-model="props.item.TypeVisit"
              id="DedValue"
              :def="1202"
              label="Tipo Visita"
              @input="removeAllMaterial(props.item.TypeVisit)"
            />
          </v-col>
          <v-col lg="4" class="s-col-form">
            <s-search-lot-by-cod-senasa @searchSenasa="searchSenasa($event)" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col lg="4" class="s-col-form" cols="12">
            <s-text
              v-if="!props.item.TvID"
              label="Fundo"
              v-model="objLot.FagName"
            />
            <s-text v-else label="Fundo" v-model="props.item.FagName" />
          </v-col>
          <v-col lg="4" class="s-col-form" cols="12">
            <s-text
              v-if="!props.item.TvID"
              v-model="objLot.PrdCardName"
              label="Productor"
            />
            <s-text v-else v-model="props.item.PrdText" label="Productor" />
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col lg="2" class="s-col-form" cols="12">
            <s-text
              v-if="!props.item.TvID"
              label="Lote"
              v-model="objLot.FltName"
            />
            <s-text v-else label="Lote" v-model="props.item.FltName" />
          </v-col>
          <v-col lg="4" class="s-col-form" cols="12">
            <!-- <s-select-worker
              v-model="props.item.NtpID"
              id="NtpID"
              label="Supervisor"
              :text="props.item.Supervisor"
              @input="getSupervData(props.item.NtpID)"
            /> -->

            <s-select-supervice
              clearable
              label="Supervisor"
              v-model="props.item.TvSupervisorPrsID"
              full
              :value="TvSupervisorPrsID"
            ></s-select-supervice>
          </v-col>
          <v-col lg="2" class="s-col-form" cols="12">
            <s-select-definition
              v-model="props.item.TvStatus"
              :def="1152"
              label="Estado"
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" lg="8" class="s-col-form">
            <s-text v-model="props.item.TvObservation" label="Observación" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col lg="8" cols="12">
            <v-card v-if="props.item != null" class="mt-3" elevation="1">
              <v-row>
                <v-col class="pt-0">
                  <s-toolbar
                    color="primary"
						        dark
                    :elevation="2"
                    remove
                    @add="addMaterial()"
                    @removed="removeMaterial()"
                    label="Materiales"
                  />
                </v-col>
              </v-row>
              <!-- el combo box -->
              <v-col lg="12" class="" cols="12">
                <s-article-search
                  v-model="ObjMaterial"
                  @input="addMaterial()"
                  return-object
                  label="Material"
                  clearable
                ></s-article-search>
              </v-col>
              <!--   la grilla-->
              <v-data-table
                v-model="selectedMaterial"
                :headers="headerMaterial"
                :items="itemsMaterial"
                item-key="AtvID"
                show-select
                label="Edit"
                dense
                class="elevation-0"
              >
                <template v-slot:item.TvaQuantity="props">
                  <s-text
                    decimal
                    autofocus
                    v-model="props.item.TvaQuantity"
                  ></s-text>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:TvStatus="{ row }">
      <v-chip
        style="margin: 0px"
        x-small
        :color="row.TvStatus == 1 ? 'success' : 'error'"
      >
        {{ row.TvStatus == 1 ? "Activo" : "Inactivo" }}
      </v-chip>
    </template>
  </s-crud>
</template>

<script>
import _technicalVisit from "@/services/Technicalassistance/TasTechnicalVisitService";
import SSearchLotByCodSenasa from "@/components/HarvestSchedule/sSearchLotByCodSenasa.vue";
import SSelectProducer from "@/components/HarvestSchedule/SSelectProducer";
import SSelectAgricultural from "@/components/HarvestSchedule/SSelectAgricultural";
import SSelectLot from "@/components/HarvestSchedule/SSelectLot";
import SSelectSupervice from "@/components/HarvestSchedule/SSelectSupervice";
// import SSelectSupervice from "@/components/Utils/SSelectSupervicevt";
import SSelectArticleTypeVisita from "../../../components/Utils/SSelectArticleTypeVisita.vue";
import SSelectVariety from "../../../components/HarvestSchedule/SSelectVariety.vue";
import SSelectDefinition from "../../../components/Utils/SSelectDefinition.vue";
import SSelectWorker from "../../../components/Utils/Worker/sSelectWorker.vue";
import SArticleSearch from "../../../components/Utils/Logistics/SArticleSearch.vue";
//import SSelectLotCV from '@/components/TecnicalAssistence/ListSupplies/SSelectLotCV.vue';
import _sHarvest from "@/services/Technicalassistance/HarvestSchedule";

import moment from 'moment'

export default {
  components: {
    SSelectProducer,
    SSelectAgricultural,
    SSelectLot,
    SSelectSupervice,
    //SSelectLotCV,
    SSelectArticleTypeVisita,
    SSelectVariety,
    SSelectDefinition,
    SSelectWorker,
    SArticleSearch,
    SSearchLotByCodSenasa,
  },
  props: {
    defID: 0,
    defDescription: "",
  },
  data: () => ({
    objLot: {},
    TvSupervisorPrsID : 0,
    itemsLots: [],
    itemsFarm: [], // ITEM DE FUNDOS SEGUN PRODUCTOR
    //lista de datos del lote necesarios para validacion
    itemsDatosLote: [],
    // lista de datos del supervisor necesarios para validacion
    itemsDatosSuperv: [],
    //configuracion grilla materiales
    itemsMaterial: [],
    selectedMaterial: [],
    headerMaterial: [
      { text: "ID", value: "AtvID" },
      { text: "Material", value: "ArtName" },
      { text: "Und", value: "Unit", sortable: false, width: "50" },
      { text: "Cantidad", value: "TvaQuantity", width: "90" },
    ],
    ObjMaterial: null,
    // fin configuracion grilla materiales
    // objetos para carga de lote
    ObjFundo: {},
    ObjLot: {
      LotObj: null,
    },
    ObjProducer: {
      ProducerObj: null,
    },
    ObjAgriculturalFarm: {
      AgriculturalFarmObj: null,
    },
    //fin carga objetos carga lote
    tvid: 0,
    idzona: 0,
    zonades: "",
    filter: {
      DateBegin: null,
      DateEnd: null,
      TvSupervisorPrsID: null,
      TypeVisit: null
    },
    config: {
      title: "Registro de Visitas Técnicas",
      service: _technicalVisit,
      
      model: {
        TvID: "ID",
        TvDate: "date",
        FltID: "int",
        NtpID: "int",
        DedValue: "int",
        SecStatus: "status",
        TvStatus: "status",
        TvObservation: "string",
        TvCultiveID: "int",
      },
      headers: [
        {
          text: "ID",
          value: "TvID",
          width: "60",
          align: "end",
          sorteable: true,
        },
        {
          text: "Fecha",
          value: "TvDate",
          sorteable: true,
        },
        {
          text: "Supervisor",
          value: "TvSupervisorName",
          sorteable: false,
        },
        {
          text: "Productor",
          value: "PrdCardName",
          sorteable: false,
        },
        {
          text: "ID Lote",
          value: "FltID",
          sorteable: true,
        },
        {
          text: "Lote",
          value: "FltName",
          sorteable: true,
        },

        {
          text: "Tipo Visita",
          value: "TypeVisitName",
          align: "center",
          sorteable: false,
        },
        {
          text: "Estado",
          value: "TvStatus",
          width: "80",
          align: "center",
          sorteable: false,
        },
      ],
    },
    currentItem: "tab-Funciones",
  }),

  // computed: {
  //   filter() {
  //     return {};
  //   },
  // },
  methods: {

    // update(item){
    //   debugger;
    //   this.NtpSuperviceID = item[0].NtpID;
    // },

    searchSenasa(event) {
      console.log("search senasa", event);
      if (event) {
        this.objLot = event;
        this.TvSupervisorPrsID = event.GenSuperviceID; 
        this.objLot.PrdCardName = event.PrdText;
      }
    },
    rowSelected(items) {
      if (items.length < 1) return;
      this.tvid = 0;
      if (items[0].FltID != 0 && items[0].FltID != null)
        this.objLot.FltID = items[0].FltID;
      if (items.length > 0) this.tvid = items[0].TvID;
      this.$emit("rowSelected", items);
      this.itemsMaterial = [];
      _technicalVisit
        .listMaterial(this.tvid)
        .then((response) => {
          let data = response.data;
          data.forEach((element) => {
            let obj = {
              AtvID: element.AtvID,
              TvID: element.TvID,
              TvaID: element.TvaID,
              ArtName: element.ArtDescription,
              Unit: element.Unit,
              TvaQuantity: element.TvaQuantity,
            };
            this.itemsMaterial.push(obj);
          });
          console.log(this.itemsMaterial);
        })
        .catch((err) => {
          console.error("err");
        });
    },

    refresh(item) {
      this.$emit("refresh", item);
    },

    //metodo guardado para invocar
    sav(item) {

       
      item.DetailsMaterial = this.itemsMaterial;
      item.SecStatus = 1;
      item.UsrCreateID	= this.$fun.getUserID();
      item.UsrUpdateID = this.$fun.getUserID();

      item.FltID = this.objLot.FltID;
      // if (item.PrsID == 0) {
      //   this.$fun.alert("Seleccione un Supervisor", "warning");
      //   return;
      // }
      if (this.objLot.FltID == 0 || this.objLot.FltID == null) {
        this.$fun.alert("Seleccione un Lote", "warning");
        return;
      }

      if(item.TvSupervisorPrsID == 0 || item.TvSupervisorPrsID == null){
        this.$fun.alert("Seleccione un supervisor", "warning");
        return;
      }

      // item.NtpID = this.NtpSuperviceID;

      item.save().then(() => {
        this.clearForm();
      });
    },
    clearForm() {
      this.objLot = {};
      this.ObjMaterial = null;
      this.itemsMaterial = [];
    },
    //agregando item a la grilla de materiales
    addMaterial() {
      let objM = {
        AtvID: this.ObjMaterial.ArtCode,
        TvID: this.ObjMaterial.TvID,
        TvaID: this.ObjMaterial.TvaID,
        ArtName: this.ObjMaterial.ArtName,
        Unit: this.ObjMaterial.UnitOfMeasuarement,
        TvaQuantity: this.ObjMaterial.TvaQuantity,
      };
      let exist = false;
      this.itemsMaterial.forEach((element) => {
        if (this.ObjMaterial.ArtCode == element.AtvID) {
          exist = true;
        }
      });
      if (exist == false) {
        if (this.tvid > 0) {
          objM.TvID = this.tvid;
        }
        this.itemsMaterial.push(objM);
      }
    },
    // remover un item de la grilla material
    removeMaterial() {
      this.selectedMaterial.forEach((element) => {
        this.itemsMaterial = this.itemsMaterial.filter(
          (x) => x.AtvID != element.AtvID
        );
      });
    },
    removeAllMaterial(id) {
      if (this.tvid > 0) {
        // this.$fun.alert("¿Desea Conservar los Materiales guardados anteriormente?","question").then(
        // (r)=>{if(r.value){} else{ this.itemsMaterial=[]; }});
      }
    },
    //traer datos del lote para validacion
    //traer datos del supervisor para validacion
    getSupervData(ntpid) {
      if (ntpid > 0) {
        _technicalVisit
          .listdatossupervisor(ntpid)
          .then((response) => {
            let data = response.data;
            data.forEach((element) => {
              let objSpv = {
                NtpID: element.NtpID,
                PrsID: element.PrsID,
                ZonID: element.ZonID,
                ZonDescription: element.ZonDescription,
              };
              this.itemsDatosSuperv = [];
              this.itemsDatosSuperv.push(objSpv);
              console.log(this.itemsDatosSuperv);
            });
          })
          .catch((err) => {
            console.error("err");
          });
      }
    },
  },

 
};
</script>
